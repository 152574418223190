<template>
    <div class="sim-text-large flex flex-col gap-3 text-balance text-center">
        <i18n-t
            v-if="remaining"
            keypath="cart.free_shipping_notice"
            tag="span"
        >
            <template #amount>
                <span class="sim-font-bold text-nowrap">
                    {{ `${Math.ceil(remaining)} ${currencySymbol ?? ''}` }}
                </span>
            </template>
            <template #free_shipping>
                <span class="sim-font-bold">
                    {{ $t('cart.free_shipping') }}
                </span>
            </template>
        </i18n-t>

        <span v-else class="sim-font-bold">
            {{ $t('cart.has_free_shipping') }}
        </span>

        <BaseUiProgressBar :progress="progress" class="mx-auto max-w-lg" />
    </div>
</template>

<script lang="ts" setup>
import type { FormattedCurrency } from '@sim-api-types/general-data'

const {
    current: _current,
    target,
    currencySymbol,
} = defineProps<{
    current: number | FormattedCurrency
    target: number
    currencySymbol?: string
}>()

const current = computed<number>(() => typeof _current === 'number' ? _current : _current.value)

const remaining = computed<number | null>(() => {
    if (current.value >= target) return null
    return target - current.value
})

const progress = computed<number>(() => {
    if (!remaining.value) return 100
    return 100 - (remaining.value / target) * 100
})
</script>

<style lang="scss" scoped>

</style>
